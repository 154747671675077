import Manvika from '../src/Assets/manvika Pharma.png';
import Manvika2 from '../src/Assets/manvika 2.png'

const ClientReviewsDetails = [
    {
        name:"Manvika Pharma",
        img:Manvika,
        rating:5
    },
    {
        name:"MCS",
        img:Manvika2,
        rating:4
    }
]

export default ClientReviewsDetails;