import React from "react";
import "./Card.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Card(props) {
  const { name, description, img, url } = props;

  function test() {
    if (!url) {
      toast("🐇 ⋆ 🐤ᴄᴏᴍɪɴɢ ꜱᴏᴏɴ...!");
    }
  }

  return (
    <div className="card">
      <div className="card-left">
        <img src={img} alt="" />
      </div>
      <div className="card-right">
        <span className="card-heading">{name}</span>
        <span className="card-discription">{description}</span>
        <div className="card-btn">
          <a href="/">Video</a>
          <a href="/">Details</a>
          {url ? (
            <a href={url} target="_blank" className="demo-button">
              Demo
            </a>
          ) : (
            <a onClick={test} className="demo-button">
              Demo
            </a>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Card;
