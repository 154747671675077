import clinic from "../src/Assets/products_image/clinic.png";
import hotel from "../src/Assets/products_image/hotel.png";
import ecom from "../src/Assets/products_image/e-commerce.png";
import restorent from "../src/Assets/products_image/restaurnet.png";
import saloon from "../src/Assets/products_image/saloon.png";
import tiffin from "../src//Assets/products_image/tiffin logo.png";
import sales from "../src/Assets/products_image/sales.png";
import pharmecy from "../src/Assets/products_image/pharmecy.png";
import hospital from "./Assets/products_image/hospital.png";
import travels from "./Assets/Travels.png";
import hrm from "./Assets/hrm.png"
const produtsData = [
  {
    name: "E-Commerce",
    discription:
      "Revolutionizing retail with seamless online shopping experience. Integrating user-friendly interface, secure transactions, and efficient logistics for ultimate customer satisfaction. Empowering businesses and consumers alike in the digital marketplace.",
    img: ecom,
    url: "https://manasvimart.in/",
  },
  {
    name: "Hotel Management System",
    discription:
      "Run your hotel freely now, manage multiple branches with one website, human recourse management system , online as well as  offline room & food booking system , get analytical reports ",
    img: hotel,
  },
  {
    name: "Clinic Management System",
    discription:
      "Amazing product for all our doctors, manage your clinic like never before,Easy and handy to work, take and manage appointment, patient & doctor, also generate tax invoice.",
    img: clinic,
    url: "https://cliniccare.online/",
  },
  {
    name: "Restaurant Management System",
    discription:
      " Run your restaurant freely now, manage multiple branch with one website, human recourse management system, online food booking system, manage point of sale, get analytical reports.",
    img: restorent,
  },
  {
    name: "Tiffin Management System",
    discription:
      "Now get full control on incoming and outgoing of your Tiffin’s , get all information about pending payments etc. get all subscribers list with address and mode of distribution at one place.",
    img: tiffin,
    url: "https://mcstiffin.in/",
  },

  {
    name: "Salon Management System ",
    discription:
      "Book appointment online, gives your customer out of the box experience, also add products which customer can buy online, create tax invoice with ease, get all your customer data at one place.",
    img: saloon,
    url: "https://www.mcssaloon.in/",
  },
  {
    name: "Pharmacy Management System",
    discription:
      "Now running pharmacy is just like drinking a cup of tea with our PMS, easy control on inventory with various features, human resource management system, accounting management, supplier & customer management, create easy tax invoice.",
    img: pharmecy,
  },
  {
    name: "Hospital Management System",
    discription:
      "Hospital Management System streamlines healthcare operations, facilitating patient management, scheduling, billing, and resource allocation for efficient, organized, and patient-centered medical services.",

    img: hospital,
    url: "https://www.hospicare.online",
  },
  {
    name: "Travel Management System",
    discription:
      "Travel Management System optimizes travel operations, simplifying itinerary management, scheduling, billing, and resource allocation for efficient, organized, and traveler-centric services.",

    img: travels,
    url:"https://www.manasvitravels.online"
  },
  {
    name:"HRM & CRM ",
    discription:"HRM software streamlines employee management, from recruitment to development, enhancing productivity and ensuring efficient HR operations for your business.",
    img:hrm,
    url:"https://www.leadsrecord.online"
  }
];

export default produtsData;
